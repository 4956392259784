import { useTranslation } from "react-i18next"

import rSubscriptionIcon from '@/assets/rsubscription.png'

import "./topicInfo.css"
import { Link } from "react-router-dom"
import { selectAlertWindow, toogleAlert } from "../address/addressSlice"
import { GetCoins } from "../alert/alertSlice"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import Alert from "@/features/alert/alert";

export interface InfoProps {
  title: string
  icon: string,
  name: string,
  belongTo?: string,
  addressCount?: number,
  tagList?: Array<string>,
  details?: string,
  blockDetail?: string,
  firstActive?: string,
  lastActive?: string,
  useSubscription?: boolean,
  subscription?: boolean,
  blockchain?: string,

  entityId?: number,
  referenceAddress?: string
}

export default function TopicInfo (prop: InfoProps) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const showAlert = useAppSelector(selectAlertWindow)
  
  return <>
    <div className="topic-info">
      <div className="left">
        <p className="title">{prop.title}</p>
        <div className="info-body">
          <img className="body-img" src={prop.icon} alt="" style={{visibility: prop.icon ? "visible":"hidden"}} />
          <div className="info">
            <p className="name">{prop.name}</p>
            { (prop.belongTo || prop.tagList) ? (
              <div className="belong-info">
                {prop.belongTo ? 
                  <p className="belong-text">{t("address.info.belong")}&nbsp;
                    { prop.entityId ? 
                      <Link to={`/entities/${prop.belongTo}/${prop.entityId}`}><span>{prop.belongTo}</span></Link> :
                      // 去除跳转钱包 <Link to={`/address/2/${prop.blockchain}/${prop.referenceAddress}`}><span>{prop.belongTo}</span></Link>
                      <span>{prop.belongTo}</span>
                    }
                    
                  </p>
                  :null
                }
                {prop.tagList ? <div className="tags">{ prop.tagList.map((i,k) => <span key={"tag-"+k}>{i}</span>) }</div>:null}
              </div>
            ) : null }
            { prop.details ? <p className="detail-info">{prop.details}</p> : null }
            { prop.blockDetail ? <p className="detail-info">{prop.blockDetail}</p> : null }
            { prop.addressCount ? <p className="address-count">{t("address.info.contains")}{prop.addressCount}{t("address.info.unit")}{t("addressText")}</p> : null }
          </div>
        </div>
      </div>
      <div className="right">

        <div className="notice">
          {
            prop.useSubscription ? (
              <img alt="" onClick={() => {
                dispatch(toogleAlert(true))
                const blockchain = prop.blockchain as string
                dispatch(GetCoins(blockchain))
                // setSubscription(!subscription)
              }} src={rSubscriptionIcon} />
            ): null
          }
        </div>
        
        {
          prop.firstActive ? (
            <div className="active-time">
              <p>{t("address.info.firstActive")}&nbsp;{prop.firstActive}</p>
              <p>{t("address.info.lastActive")}&nbsp;{prop.lastActive}</p>
            </div>
          ) : null
        }
        
      </div>
    </div>

    { showAlert ? <Alert /> : null }
  </>
}